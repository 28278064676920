.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: $colorAccent2;
  padding: 20px 0;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 10px 0;
  }

  .header-container {
    @extend .container;
    align-items: center;
    justify-content: center;

    @media (max-width: 1023px) {
      justify-content: space-between;
    }

    .header-logo {
      display: flex;

      svg {
        height: 54px;

        @media (max-width: 1023px) {
          height: 30px;
        }

        @media (max-width: 767px) {
          height: 20px;
        }
      }
    }

    .header-nav {
      display: flex;
      align-items: center;
      margin-left: 150px;

      @media (max-width: 1023px) {
        margin-left: 0;
      }

      @media (max-width: 767px) {
        display: none;
      }

      .header-link {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 123%;
        color: #FFFFFF;
        margin-right: 60px;

        @media (max-width: 1023px) {
          margin-right: 30px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .header-button {
      @extend .button;
    }
  }
}
