.form-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0 0;
  background-color: #fff;

  @media (max-width: 1200px) {
    padding-top: 80px;
  }

  @media (max-width: 767px) {
    padding-top: 40px;
  }

  .form-section-container {
    @extend .container;
    flex-direction: column;
    align-items: center;

    .form-section-heading {
      max-width: 730px;
      font-style: normal;
      font-weight: bold;
      font-size: 62px;
      line-height: 101.9%;
      text-align: center;
      color: $colorAccent2;
      margin: 0 0 10px;

      @media (max-width: 1200px) {
        font-size: 42px;
      }

      @media (max-width: 767px) {
        font-size: 28px;
      }
    }

    .form-section-text {
      max-width: 1000px;
      font-family: $fontWork;
      font-style: normal;
      font-weight: normal;
      font-size: 29px;
      line-height: 155.4%;
      text-align: center;
      color: $colorAccent1;
      margin: 0 0 24px;

      @media (max-width: 1200px) {
        font-size: 18px;
        max-width: 700px;
      }

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    .message {
      width: 100%;
      max-width: 690px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      padding: 10px;
      color: $lightText;
      border: 1px solid $lightText;
      border-radius: 4px;
      background-color: rgba($lightText, .2);
      margin: 0 0 40px;

      &.notice {
        color: $colorSuccess;
        border: 1px solid $colorSuccess;
        background-color: rgba($colorSuccess, .2);
      }

      &.error {
        color: $colorError;
        border: 1px solid $colorError;
        background-color: rgba($colorError, .2);
      }
    }

    .form {
      width: 100%;
      max-width: 690px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-heading {
        width: 100%;
        font-family: $fontWork;
        font-style: normal;
        font-weight: 600;
        font-size: 29px;
        line-height: 155.4%;
        color: $colorAccent2;
        padding-bottom: 8px;
        border-bottom: 2px solid #E5B720;
        margin: 0 0 32px;

        @media (max-width: 767px) {
          font-size: 18px;
        }
      }

      .form-columns-container {
        width: 100%;
        display: flex;
        margin-bottom: 55px;

        &:nth-child(2) .form-column {
          border-color: #fff;
        }

        @media (max-width: 767px) {
          flex-direction: column;
          margin-bottom: 40px;
        }

        .form-column {
          width: calc(50% - 1px);
          border-right: 2px solid #E5B720;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-right: 30px;

          @media (max-width: 767px) {
            border-right: none;
            width: 100%;
            padding-right: 0;
          }

          &:last-child {
            border-right: 0;
            padding-right: 0;
            padding-left: 30px;

            @media (max-width: 767px) {
              padding-left: 0;
            }
          }
        }
      }

      .form-other-grid {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;

        @media (max-width: 767px) {
          margin-bottom: 20px;
        }

        .input-container.mid-length {
          width: 50%;
          padding-right: 30px;

          @media (max-width: 767px) {
            width: 100%;
            padding-right: 0;
          }

          &:nth-child(3) {
            padding-right: 0;
            padding-left: 30px;

            @media (max-width: 767px) {
              padding-left: 0;
            }
          }
        }
      }

      .form-submit {
        @extend .button;
        transform: translateY(50%);
      }
    }
  }
}
