.slider {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0 120px;
  background-color: #F6F2E0;

  @media (max-width: 1200px) {
    padding-bottom: 80px;
  }

  //@media (max-width: 767px) {
  //  padding-top: 40px;
  //}

  .slider-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-light {
      height: 55px;
      margin-bottom: 60px;
    }

    .slider-heading {
      font-style: normal;
      font-weight: 900;
      font-size: 70px;
      line-height: 100%;
      text-align: center;
      color: $colorAccent1;
      margin: 0 0 10px;
    }

    .slider-text {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 120%;
      text-align: center;
      color: $colorAccent2;
      margin: 0 0 20px;
    }

    .slider-button {
      @extend .button;
      margin-bottom: 40px;
    }

    .slider-element {
      width: 100%;
      display: flex;

      .flickity-viewport {
        width: 100%;
      }

      .slider-item {
        width: 50%;
        opacity: .5;
        transform: scale(.75);
        transition: all ease-out .2s;

        @media (max-width: 1200px) {
          width: 75%;
          transform: scale(.8);
        }

        @media (max-width: 767px) {
          width: 80%;
          transform: scale(1);
          margin: 0 10px;
          opacity: 1;
        }

        &.is-selected {
          opacity: 1;
          transform: scale(1);
        }

        .item-container {
          @extend .container;
          padding: 10px 22px 35px 55px;
          background-color: #fff;
          border-radius: 8px;

          @media (max-width: 1023px) {
            padding: 10px 20px 20px 20px;
          }

          @media (max-width: 767px) {
            padding: 20px;
          }

          .content-side {
            width: 44%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-right: 10px;
            padding-top: 65px;

            @media (max-width: 1023px) {
              width: 67%;
              padding-top: 10px;
            }

            @media (max-width: 767px) {
              width: 100%;
              padding-top: 0;
            }

            .item-supheading {
              font-style: normal;
              font-weight: bold;
              font-size: 24.75px;
              line-height: 122.9%;
              color: $colorAccent2;
              margin: 0 0 -5px;

              @media (max-width: 767px) {
                font-size: 18px;
                margin: 0;
              }
            }

            .item-heading {
              font-style: normal;
              font-weight: 800;
              font-size: 39px;
              line-height: 100%;
              color: $colorAccent2;
              padding-bottom: 5px;
              border-bottom: 2px solid $colorAccent1;
              margin: 0 0 10px;

              @media (max-width: 1023px) {
                font-size: 32px;
              }

              @media (max-width: 767px) {
                font-size: 24px;
              }
            }

            .item-subheading {
              font-family: $fontWork;
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 122.9%;
              color: $colorAccent2;
              margin: 0 0 10px;

              @media (max-width: 767px) {
                font-size: 16px;
              }
            }

            .item-text {
              font-family: $fontWork;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 122.9%;
              color: #638BBF;
              margin: 0;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
          }

          .image-side {
            width: 56%;
            display: flex;

            @media (max-width: 1023px) {
              width: 33%;
            }

            @media (max-width: 767px) {
              display: none;
            }

            .item-image {
              width: 100%;
              object-fit: contain;
              object-position: center bottom;

              @media (max-width: 1023px) {
                object-position: center center;
              }
            }
          }
        }
      }
    }

    .slider-dots {
      margin-top: 40px;
      display: flex;
      align-items: center;

      .slider-dot {
        width: 18px;
        height: 18px;
        border-radius: 18px;
        background-color: $colorAccent1;
        margin: 0 5px;
        transition: width ease-out .2s;
        box-shadow: 0 0 10px 0 rgba($dark, .15);
        cursor: pointer;

        &.is-active {
          width: 46px;
        }
      }
    }
  }
}
