// --------------------------------------------------
// Variables
// --------------------------------------------------

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Fonts
$fontNunito: 'Nunito Sans', sans-serif;
$fontWork: 'Work Sans', sans-serif;

// Accents Colors
$colorAccent1: #F6D947;
$colorAccent2: #1C1666;
$colorAccent3: #FFCB07;

$lightText: #73879A;

// Texts
$dark: #0D313F;
$grey: #F4F8FF;
$light: #FFFFFF;

$colorSuccess: #00966D;
$colorError: #FF564A;
