.hero-banner {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 0 80px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkyMSIgaGVpZ2h0PSI4MzkiIHZpZXdCb3g9IjAgMCAxOTIxIDgzOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5MjAuMyAwVjcxNkMxMTM5LjYgOTAxLjUgNjI4LjUgODU2LjIgMC4yOTk5ODggNzE2VjBIMTkyMC4zWiIgZmlsbD0iIzFDMTY2NiIvPgo8L3N2Zz4K");
  background-position: center bottom;
  background-size: 101% auto;
  background-repeat: no-repeat;
  background-color: #F6F2E0;

  @media (max-width: 1023px) {
    background-position: center bottom 50px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    left: -2px;
    height: 50%;
    background-color: $colorAccent2;

    @media (max-width: 1023px) {
      height: 75%;
    }
  }

  .hero-banner-container {
    @extend .container;
    align-items: flex-start;
    position: relative;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }

    .hero-content {
      flex: 1;
      max-width: 548px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 1023px) {
        max-width: auto;
        align-items: center;
      }

      .hero-heading {
        font-style: normal;
        font-weight: 900;
        font-size: 58px;
        line-height: 122%;
        color: #FFFFFF;
        margin: 0 0 30px;

        @media (max-width: 1200px) {
          font-size: 45px;
        }

        @media (max-width: 1023px) {
          font-size: 32px;
          text-align: center;
        }

        @media (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 20px;
        }
      }

      .hero-text {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 122.9%;
        color: #E5B720;
        margin: 0 0 30px;

        @media (max-width: 1023px) {
          font-size: 16px;
          text-align: center;
        }
      }

      .hero-buttons {
        display: flex;

        @media (max-width: 767px) {
          flex-direction: column;
        }

        .hero-button {
          @extend .button;
          margin: 0 20px 20px 0;

          @media (max-width: 767px) {
           margin-right: 0;
            margin-bottom: 10px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .hero-image-container {
      flex: 1;
      display: flex;

      @media (max-width: 1023px) {
        max-width: 600px;
        text-align: center;
      }

      .hero-image {
        width: 100%;
      }
    }
  }
}
