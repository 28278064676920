.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $colorAccent2;
  padding: 120px 0;

  @media (max-width: 1023px) {
    padding: 80px 0 40px;
  }

  .footer-container {
    @extend .container;
    flex-direction: column;
    align-items: center;

    .footer-logo {
      height: 55px;
      margin-bottom: 45px;

      @media (max-width: 767px) {
        height: 30px;
        margin-bottom: 40px;
      }
    }

    .footer-wrapper {
      width: 100%;
      max-width: 850px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @media (max-width: 1023px) {
        flex-direction: column-reverse;
        align-items: center;
      }

      .left-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 1023px) {
          align-items: center;
        }

        .footer-address {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 143.4%;
          color: #638BBF;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 2px solid $colorAccent1;

          @media (max-width: 1023px) {
            text-align: center;
            font-size: 16px;
          }

          @media (max-width: 767px) {
            font-size: 14px;
          }
        }

        .footer-mail {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 143.4%;
          color: #638BBF;

          @media (max-width: 1023px) {
            text-align: center;
            font-size: 16px;
          }
        }
      }

      .right-section {
        display: flex;

        @media (max-width: 1023px) {
          margin-bottom: 40px;
        }

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }

        .links-column {
          min-width: 200px;
          display: flex;
          flex-direction: column;
          margin-right: 70px;

          @media (max-width: 767px) {
            margin-right: 0;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 2px solid $colorAccent1;

            &:last-child {
              padding-bottom: 0;
              margin-bottom: 0;
              border-bottom: none;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          .footer-link {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 122.9%;
            color: #FFFFFF;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 2px solid $colorAccent1;

            &:last-child {
              padding-bottom: 0;
              margin-bottom: 0;
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
