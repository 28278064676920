.terms-wrapper {
  padding: 80px 20px;
  background-color: $light;

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .terms-content {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    p {
      font-family: $fontWork;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 120%;
      color: $colorAccent2;
      margin: 0 0 30px;

      @media (max-width: 1023px) {
        font-size: 16px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        margin: 0 0 20px;
      }

      &.page-title {
        font-family: $fontNunito;
        font-weight: bold;
        font-size: 62px;
        line-height: 115%;
        color: #638BBF;
        margin-bottom: 40px;

        @media (max-width: 1023px) {
          font-size: 45px;
        }

        @media (max-width: 767px) {
          font-size: 28px;
          margin-bottom: 30px;
        }
      }

      &.page-subtitle {
        font-size: 29px;
        line-height: 125%;
        color: $colorAccent1;
        margin-bottom: 40px;

        @media (max-width: 1023px) {
          font-size: 24px;
        }

        @media (max-width: 767px) {
          font-size: 18px;
          margin-bottom: 30px;
        }
      }

      &.page-heading {
        font-weight: 600;
        font-size: 29px;
        line-height: 75%;
        color: $colorAccent2;
        margin-top: 60px;

        @media (max-width: 1023px) {
          font-size: 24px;
        }

        @media (max-width: 767px) {
          font-size: 18px;
          margin-top: 30px;
        }
      }

      &.page-big-heading {
        font-weight: 900;
        font-size: 36px;
        line-height: 100%;
        color: $colorAccent1;
        margin-top: 60px;

        @media (max-width: 1023px) {
          font-size: 28px;
        }

        @media (max-width: 767px) {
          font-size: 22px;
          margin-top: 30px;
        }
      }

      &.page-sub-heading {
        font-weight: bold;
        font-size: 23px;
        line-height: 95%;
        color: $colorAccent1;
        margin-top: 40px;

        @media (max-width: 1023px) {
          font-size: 18px;
        }

        @media (max-width: 767px) {
          font-size: 16px;
          margin-top: 20px;
        }
      }

      &.page-footer {
        font-weight: 600;
        font-size: 29px;
        line-height: 100%;
        color: #638BBF;
        margin-top: 60px;

        @media (max-width: 1023px) {
          font-size: 24px;
        }

        @media (max-width: 767px) {
          font-size: 18px;
          margin-top: 20px;
        }
      }
    }

    img {
      margin: 0 auto 40px;
      max-width: 100%;
      height: 100%;
    }

    a {
      text-decoration: underline;
      color: $colorAccent1;
    }
  }
}
