.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;

  &:nth-child {
    margin-bottom: 0;
  }

  .form-label {
    font-family: $fontWork;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 155.4%;
    color: $colorAccent2;
    margin: 0 0 10px;

    @media (max-width: 767px) {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  .form-input, .form-select {
    width: 100%;
    font-family: $fontWork;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 155.4%;
    color: $colorAccent2;
    padding: 8px 12px;
    border: 1px solid #638BBF;
    border-radius: 8px;
    appearance: none;
    outline: none;

    @media (max-width: 767px) {
      font-size: 16px;
    }

    &::placeholder {
      color: rgba($colorAccent2, .5);
    }
  }

  .form-select {
    @extend .form-input;
    padding-right: 36px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCA5Ij48cGF0aCBkPSJtMSAxLjUgNS43IDUuOEwxMy4xIDEiIHN0cm9rZT0iIzYzOEJCRiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+PC9zdmc+");
    background-size: 12px 7px;
    background-position: right 12px center;
    background-repeat: no-repeat;
  }

  .form-textarea {
    @extend .form-input;
    min-height: 160px;
  }
}

#delici-ghost {
  display: none;
}
