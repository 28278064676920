.split {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 120px 0 100px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkyMSIgaGVpZ2h0PSI4MzkiIHZpZXdCb3g9IjAgMCAxOTIxIDgzOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5MjAuMyAwVjcxNkMxMTM5LjYgOTAxLjUgNjI4LjUgODU2LjIgMC4yOTk5ODggNzE2VjBIMTkyMC4zWiIgZmlsbD0iIzFDMTY2NiIvPgo8L3N2Zz4K");
  background-position: center bottom;
  background-size: 101% auto;
  background-repeat: no-repeat;

  @media (max-width: 1023px) {
    padding: 80px 0;
    background-position: center bottom;
  }

  @media (max-width: 767px) {
    padding: 40px 0 80px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    left: -2px;
    height: calc(100% - 8vw);
    background-color: $colorAccent2;
  }

  .split-container {
    @extend .container;
    position: relative;
    flex-direction: column;
    align-items: center;

    .split-item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      @media (max-width: 1200px) {
        align-items: flex-start;
      }

      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: center;
      }

      &:nth-child(2) {
        flex-direction: row-reverse;
        margin-bottom: 200px;

        @media (max-width: 1023px) {
          flex-direction: column;
          margin-bottom: 80px;
        }
      }

      .content-side {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 1023px) {
          width: 100%;
        }

        .content-heading {
          font-style: normal;
          font-weight: bold;
          font-size: 62px;
          line-height: 106.4%;
          color: #FFFFFF;
          margin: 0 0 40px;

          @media (max-width: 1200px) {
            font-size: 42px;
          }

          @media (max-width: 767px) {
            font-size: 28px;
          }
        }

        .content-bold {
          font-family: $fontWork;
          font-style: normal;
          font-weight: 600;
          font-size: 21px;
          line-height: 155.4%;
          color: $colorAccent1;
          margin: 0;

          @media (max-width: 1200px) {
            font-size: 18px;
          }

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        .content-text {
          font-family: $fontWork;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 155.4%;
          color: #638BBF;
          margin: 0 0 20px;

          @media (max-width: 1200px) {
            font-size: 18px;
          }

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        .content-list {
          width: 100%;
          margin: 0;
          padding: 0;
          list-style: none;
          margin-bottom: 75px;

          @media (max-width: 767px) {
            margin-bottom: 40px;
          }

          li {
            position: relative;
            width: 100%;
            font-family: $fontWork;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 155.4%;
            color: $colorAccent1;
            padding-left: 20px;
            margin-bottom: 16px;

            @media (max-width: 1200px) {
              font-size: 16px;
            }

            @media (max-width: 767px) {
              font-size: 14px;
            }

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: calc(50%);
              transform: translateY(-50%);
              width: 6px;
              height: 6px;
              border-radius: 6px;
              background-color: $colorAccent1;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .content-subheading {
          font-style: normal;
          font-weight: bold;
          font-size: 31px;
          line-height: 129.4%;
          color: #FFFFFF;
          margin: 0;

          @media (max-width: 1200px) {
            font-size: 24px;
          }

          @media (max-width: 767px) {
            font-size: 18px;
          }
        }
      }

      .image-side {
        width: 50%;
        display: flex;

        @media (max-width: 1023px) {
          width: 75%;
        }

        @media (max-width: 767px) {
          width: 100%;
        }

        .split-image {
          width: 100%;
        }
      }
    }

    .split-logo {
      height: 55px;

      @media (max-width: 767px) {
        height: 30px;
      }
    }
  }
}
