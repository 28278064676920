.argument02 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #FFF;
  padding: 100px 0 120px;

  @media (max-width: 1200px) {
    padding: 80px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }

  .arguments02-container {
    @extend .container;
    flex-direction: column;
    align-items: center;

    .arguments02-heading {
      max-width: 810px;
      font-style: normal;
      font-weight: bold;
      font-size: 62px;
      line-height: 101.9%;
      text-align: center;
      color: $colorAccent2;
      margin: 0 0 20px;

      @media (max-width: 1200px) {
        font-size: 42px;
        max-width: 610px;
      }

      @media (max-width: 767px) {
        font-size: 28px;
      }
    }

    .arguments02-text {
      position: relative;
      max-width: 1160px;
      font-style: normal;
      font-weight: normal;
      font-size: 29px;
      line-height: 155.4%;
      text-align: center;
      color: #638BBF;
      padding-bottom: 100px;
      margin-bottom: 80px;

      @media (max-width: 1200px) {
        font-size: 24px;
        max-width: 900px;
      }

      @media (max-width: 1023px) {
        font-size: 18px;
        max-width: 600px;
      }

      @media (max-width: 767px) {
        font-size: 16px;
        padding-bottom: 40px;
        margin-bottom: 40px;
      }

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 500px;
        height: 2px;
        background-color: #638BBF;

        @media (max-width: 1200px) {
          width: 300px;
        }

        @media (max-width: 767px) {
          width: 150px;
          height: 1px;
        }
      }
    }

    .arguments02-subheading {
      max-width: 810px;
      font-style: normal;
      font-weight: bold;
      font-size: 62px;
      line-height: 101.9%;
      text-align: center;
      color: $colorAccent2;
      margin: 0 0 55px;

      @media (max-width: 1200px) {
        font-size: 42px;
        max-width: 610px;
      }

      @media (max-width: 1023px) {
        font-size: 28px;
        max-width: 610px;
      }

      @media (max-width: 767px) {
        font-size: 24px;
      }
    }

    .arguments02-list {
      width: 100%;
      max-width: 950px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 1200px) {
        max-width: 700px;
      }

      @media (max-width: 1023px) {
        max-width: 610px;
      }

      .arguments02-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;

        @media (max-width: 767px) {
          margin-bottom: 40px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .item-icon {
          height: 70px;
          margin-bottom: 12px;

          @media (max-width: 767px) {
            height: 50px;
          }
        }

        .item-heading {
          font-style: normal;
          font-weight: bold;
          font-size: 29px;
          line-height: 122.9%;
          text-align: center;
          color: $colorAccent2;
          margin: 0 0 5px;

          @media (max-width: 1200px) {
            font-size: 24px;
          }

          @media (max-width: 767px) {
            font-size: 18px;
          }
        }

        .item-text {
          font-style: normal;
          font-weight: normal;
          font-size: 29px;
          line-height: 160%;
          text-align: center;
          color: #638BBF;
          margin: 0;

          .scroll-link {
            text-decoration: underline;
          }

          @media (max-width: 1200px) {
            font-size: 18px;
          }

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
