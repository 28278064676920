.button {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $fontNunito;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 123%;
  text-align: center;
  padding: 18px 45px;
  border-radius: 8px;
  color: $colorAccent2;
  background-color: $colorAccent1;
  transition: all ease-out 0.2s;
  cursor: pointer;
  box-shadow: 0 0 20px 0 rgba($dark, .25);
  appearance: none;
  border: none;
  outline: none;

  @media (max-width: 767px) {
    padding: 10px 14px;
    font-size: 14px;
    border-radius: 4px;
  }

  &:hover {
    background-color: #f9e27b;
  }
}
