.argument01 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #F6F2E0;
  padding: 100px 0 120px;

  @media (max-width: 1200px) {
    padding: 80px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
  }

  .arguments01-container {
    @extend .container;
    flex-direction: column;
    align-items: center;

    .arguments01-heading {
      font-style: normal;
      font-weight: bold;
      font-size: 62px;
      line-height: 122.9%;
      text-align: center;
      color: $colorAccent2;
      margin: 0 0 6px;

      @media (max-width: 1200px) {
        font-size: 42px;
      }

      @media (max-width: 767px) {
        font-size: 28px;
      }
    }

    .arguments01-text {
      font-family: $fontWork;
      font-style: normal;
      font-weight: normal;
      font-size: 29px;
      line-height: 122.9%;
      text-align: center;
      color: #638BBF;
      margin: 0 0 54px;
      max-width: 594px;

      @media (max-width: 1200px) {
        font-size: 24px;
      }

      @media (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 40px;
      }
    }

    .arguments01-list {
      width: 100%;
      max-width: 1080px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .arguments01-item {
        width: calc((100% - 40px) / 3);
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 767px) {
          width: 100%;
          align-items: flex-start;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .item-icon {
          height: 68px;
          margin-bottom: 26px;

          @media (max-width: 767px) {
            height: 40px;
            margin-bottom: 10px;
          }
        }

        .item-heading {
          font-style: normal;
          font-weight: bold;
          font-size: 24.75px;
          line-height: 122.9%;
          text-align: center;
          color: $colorAccent2;
          margin: 0 0 15px;

          @media (max-width: 1200px) {
            font-size: 18px;
          }

          @media (max-width: 767px) {
            text-align: left;
          }
        }

        .item-text {
          max-width: 230px;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 122.9%;
          text-align: center;
          color: $colorAccent2;
          margin: 0;

          @media (max-width: 1200px) {
            font-size: 16px;
          }

          @media (max-width: 767px) {
            text-align: left;
            max-width: none;
          }
        }
      }
    }
  }
}
